import styled from "styled-components";

export const GSPSMediaPreviewContainer = styled.div`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 500px;
        width: 800px;
        background-color: ${({theme}) => theme.color.bodyBackground};
        border: 2px solid ${({theme}) => theme.color.border};
        border-radius: 12px;
        box-shadow: 24;

        @media (max-width: 820px) {
            width: 90%;
        }

        & .media {
            width: auto;
            max-width: 100%;
            height: 100%;
            object-fit: contain
        }

        & .closeBtn {
            position: absolute;
            top: 3px;
            right: 3px;
            z-index: 5000;
            color: ${({theme}) => theme.color.textOnBg};
        }
`