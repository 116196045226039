import styled from "styled-components";

export const FilterContainer = styled.div`
    min-width: 440px;
    .title{
        font-size: 14px;
        font-weight: 400;
        color: ${ ({ theme }) => theme.color.textOnBg }
    }
    button{
        color: #4CA2A8;
        font-size: 12px;
        font-weight: 400;
        text-transform: none;
    }
    button.MuiButton-contained{
        background: ${ ({ theme }) => theme.color.info };
        color: ${ ({ theme }) => theme.color.textOnBg }
    }
    button.MuiButton-outlined{
        border-color: ${ ({ theme }) => theme.color.info };
        color: ${ ({ theme }) => theme.color.textOnBg };
    }
    .MuiCheckbox-root{
        color: #A0A0A0;
    }
    & .Mui-checked, .MuiCheckbox-indeterminate{
        color: #4CA2A8 !important;
    }
    .childOpt {
        white-space: nowrap; 
        overflow: hidden;    
        text-overflow: ellipsis; 
    }

`