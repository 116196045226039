import React, { useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import { GSPSContainer, RequiredFormFieldIcon } from "./GSPSLabeledInput.styles";


export const PatternType = {
    ZIP: {
        value: /^\d{5}$/,
        message: "Zip code should be 5 digits",
    },
    EMAIL: {
        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: "Please use a valid email format",
    },
};

export const GSPSLabelledInput = ({
    id,
    labelName,
    controlType,
    register,
    errors,
    placeHolder,
    value,
    isInvalid,
    patternType,
    validate,
    isRequired = false,
    isDisabled = false,
    isPhone = false,
    readOnly = false,
    hiddenLabel = false,
    setValue = null,
    onKeyDown,
}) => {

    useEffect(() => {
        if (setValue && value) {
            setValue(id, value);
        }
    }, [setValue]);

    let displayPlaceholder = placeHolder;
    if (hiddenLabel && !isRequired && placeHolder) {
        displayPlaceholder = `${placeHolder} (Optional)`;
    }

    function getErrorMessage() {
        if (!errors) {
            return;
        }

        const [baseID, secondaryID] = id.split(".");
        const baseErrorDict = errors?.[baseID];

        if (baseErrorDict && secondaryID) {
            return baseErrorDict[secondaryID]?.message;
        }

        return baseErrorDict?.message;
    }

    const validatePhoneNumber = (value) => {
        const unmaskedValue = value.replace(/\D/g, "");
        return (unmaskedValue.length === 10 || unmaskedValue.length === 0) || "Phone number must be exactly 10 digits.";
    };

    return <>
        <GSPSContainer className="my-3">
            {
                !hiddenLabel &&
                <label column htmlFor={id} className="pb-0 label-sm">
                    {labelName}: {isRequired && <RequiredFormFieldIcon>* </RequiredFormFieldIcon>}
                </label>
            }
            <Col>
                {isPhone ?
                    <InputMask
                        mask="(999) 999-9999"
                        defaultValue={value}
                        disabled={isDisabled}
                        readOnly={readOnly}
                        required={isRequired}
                        {...register(
                            id,
                            {
                                required: isRequired && { value: true, message: "This field is required." },
                                pattern: patternType && patternType,
                                validate: (value) => validate || validatePhoneNumber(value),
                            },
                        )}
                    >
                        {(inputProps) => (
                            <Form.Control
                                {...inputProps}
                                id={id}
                                type={controlType}
                                placeholder={placeHolder}
                                isInvalid={isInvalid || getErrorMessage()}
                            />
                        )}
                    </InputMask> :
                    <Form.Control
                        id={id}
                        type={controlType}
                        placeholder={displayPlaceholder}
                        defaultValue={value}
                        disabled={isDisabled}
                        isInvalid={isInvalid || getErrorMessage()}
                        readOnly={readOnly}
                        onKeyDown={(e) => {
                            if (onKeyDown) onKeyDown(e);
                        }}
                        {...register(
                            id,
                            {
                                required: isRequired && { value: true, message: "This field is required." },
                                pattern: patternType && patternType,
                                validate: validate,
                            },
                        )}
                    />
                }
                <Form.Control.Feedback type="invalid">
                    {isInvalid || getErrorMessage()}
                </Form.Control.Feedback>
            </Col>
        </GSPSContainer>
    </>;
};


GSPSLabelledInput.propTypes = {
    id: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    controlType: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    patternType: PropTypes.object,
    placeHolder: PropTypes.string,
    value: PropTypes.string,
    isInvalid: PropTypes.object,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    validate: PropTypes.func,
    onKeyDown: PropTypes.func,
    isPhone: PropTypes.bool,
    readOnly: PropTypes.bool,
    setValue: PropTypes.func,
    hiddenLabel: PropTypes.bool,
};
