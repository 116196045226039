import React, { useContext, useEffect, useState } from "react";
import { GSPSLabelledInput } from "Components/Common/GSPSLabelledInput";
import {
    fetchSelectionOptions,
    makePatchRequest,
} from "Services/ServicesUtils";
import { Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { GSPSSubmitButton } from "Components/Common/GSPSSubmitButton";
import { GSPSCancelButton } from "Components/Common/GSPSCancelButton";
import { GSPSLabelledSelectField } from "Components/Common/GSPSLabelledSelectField";
import { insertNotification } from "Utils/NotificationsUtils";
import { SettingsStyledContainer, SettingsStyledHeader, BtnsContainer, StyledSettingsRow, SettingsTitle, SettingsBodyContainer } from "Pages/Subscriber/Subscriber.styles";
import GSPSBreadCrumbs from "../../Components/Common/GSPSBreadCrumbs";
import { UserContext } from "context/UserContext";
import { API_USERS } from "Services/Endpoints";
import { API_AUDITS } from "Models/Audit/AuditEndpoints";
import { Backdrop, CircularProgress } from "@mui/material";
import { GSPSDashboardColumnsSelect } from "Components/Profile/GSPSDashboardColumnsSelect";

export const Profile = () => {
    const { user, permissions, reloadUser } = useContext(UserContext);
    const { handleSubmit, register, setValue, getValues, formState: { isDirty, touched, errors } } = useForm();
    const [auditTypes, setAuditTypes] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedDashboardCols, setSelectedDashboardCols] = useState([]);

    useEffect(() => {
        fetchSelectionOptions(API_AUDITS.auditsTypes, setAuditTypes);
    }, []);

    const onSubmit = async (values) => {
        setIsSubmitting(true);

        const body = {
            default_audit_type_id: values.default_audit_type || null,
            default_dashboard_columns: selectedDashboardCols
        };
        await makePatchRequest(`${API_USERS.users}${user.id}/`, body)
            .then((jsonRes) => {
                insertNotification("Success",
                    `${jsonRes.full_name} has been updated.`,
                    "success");
            }).catch((error) => {
                error.then((jsonErr) => {
                    insertNotification("Error", `Can't update user, reason: ${JSON.stringify(jsonErr)}.`, "error");
                });
            })
        reloadUser();
        setIsSubmitting(false);
    };

    return (
        <>
            <GSPSBreadCrumbs staticCrumb="Settings - User Information" />
            <>
                <SettingsStyledContainer>
                    <SettingsStyledHeader>
                        <SettingsTitle>
                            Settings - User Information
                        </SettingsTitle>
                        <BtnsContainer>
                            <GSPSCancelButton
                                controlSize={0}
                                offset={0}
                                buttonText={"Cancel"}
                                secondaryBtn={true}
                                onClick={() => navigate("/dashboard")}
                            />
                            <GSPSSubmitButton isDisabled={(!isDirty && touched) ||
                                errors.zip?.message.length > 0 || isSubmitting}
                                isLoading={isSubmitting}
                                controlSize={0}
                                offset={0}
                                padding={0}
                                buttonText={"Save"}
                                onClick={handleSubmit(onSubmit)} />
                        </BtnsContainer>
                    </SettingsStyledHeader>
                    {user && auditTypes ?
                        <>
                            <SettingsBodyContainer>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <StyledSettingsRow>
                                        <Col xs={10} md={5}>
                                            <GSPSLabelledInput
                                                id="full_name"
                                                controlType={"input"}
                                                value={user?.full_name}
                                                labelName={"Full Name"}
                                                register={register}
                                                errors={errors}
                                                setValue={setValue}
                                                isDisabled={true} />

                                            <GSPSLabelledInput
                                                id="email"
                                                controlType={"input"}
                                                value={user?.email}
                                                labelName={"Email"}
                                                register={register}
                                                setValue={setValue}
                                                isDisabled={true}
                                                errors={errors} />
                                        </Col>

                                        <Col xs={10} md={5}>
                                            <GSPSLabelledInput
                                                id="role"
                                                controlType={"input"}
                                                value={user?.role?.name}
                                                labelName={"Role"}
                                                register={register}
                                                setValue={setValue}
                                                isDisabled={true}
                                                errors={errors}
                                            />
                                            <GSPSLabelledInput
                                                id="permission"
                                                controlType={"input"}
                                                value={user?.group_permission?.name}
                                                labelName={"Permission"}
                                                register={register}
                                                setValue={setValue}
                                                isDisabled={true}
                                                errors={errors}
                                            />

                                        </Col>
                                        <Col xs={10} md={5}>
                                            <GSPSLabelledSelectField
                                                inputID="default_audit_type"
                                                controlType={"input"}
                                                defaultValue={user?.default_audit_type?.id}
                                                selectableOptions={auditTypes}
                                                labelName={"Default Audit Type"}
                                                register={register}
                                                errors={errors}
                                            />
                                        </Col>
                                        <Col xs={10} md={5}>
                                            <GSPSDashboardColumnsSelect
                                                selectedDashboardCols={selectedDashboardCols}
                                                setSelectedDashboardCols={setSelectedDashboardCols} />
                                        </Col>
                                    </StyledSettingsRow>
                                </Form>
                            </SettingsBodyContainer>
                        </> : <Backdrop sx={{ color: '#fff', zIndex: "555" }} open={true}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    }
                </SettingsStyledContainer>
            </>
        </>
    );
};
