import React, {useState, useEffect} from "react";
import {GSPSLabelledInput, PatternType} from "Components/Common/GSPSLabelledInput";
import {
    fetchSelectionOptions,
    makeGetRequest,
    makePostRequest,
} from "Services/ServicesUtils";
import {API_SUBSCRIBER} from "Models/Subscriber/SubscriberEndpoints";
import {Col, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {API_USERS} from "Services/Endpoints";
import {insertNotification} from "Utils/NotificationsUtils";
import {AddressForm} from "Components/Common/AddressForm";
import {
    SettingsStyledContainer,
    SettingsStyledHeader, BtnsContainer,
    StyledSettingsRow, SettingsTitle,
    SettingsBodyContainer,
} from "Pages/Subscriber/Subscriber.styles";
import {Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {Backdrop, CircularProgress} from "@mui/material";
import PropTypes from "prop-types";
import {GSPSLabelledSelectField} from "Components/Common/GSPSLabelledSelectField";

export const CreateSubscriber = () => {
    const {handleSubmit, register, setValue, formState: {isDirty, touched, errors}, reset, getValues} = useForm();
    const [industries, setIndustries] = useState();
    const [roles, setRoles] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchSelectionOptions(API_USERS.industryTypes, setIndustries, "name");
        fetchSelectionOptions(API_USERS.roles, setRoles, "name");
        console.log(getValues());
    }, []);

    const onSubmit = async (values) => {
        setIsSubmitting(true);
        const {first_name, last_name, email, role_id, industry_type_id, ...subscriber} = values;
        const userData = {
            first_name,
            last_name,
            email,
            role_id,
            industry_type_id,
        };
        
        const data = {
            ...subscriber,
            super_user: userData,
        };
        console.log(data);
        makePostRequest(API_SUBSCRIBER.subscribers, data)
        .then(() => {

            insertNotification("Success", `Subscriber is created successfully.`, "success");
            reset();
            
        })
        .catch(async (e) => {
            const error = await e;
            insertNotification("Error", `${JSON.stringify(error)}`, "error");
        })
        .finally(() => {
            setIsSubmitting(false);
        });
    };

    return (
        <>
            <GSPSBreadCrumbs staticCrumb="subscriber" />
            <>
                <SettingsStyledContainer>
                    <SettingsStyledHeader>
                        <SettingsTitle>
                            Create a subscriber
                        </SettingsTitle>
                        <BtnsContainer>
                            <GSPSSubmitButton isDisabled={(!isDirty && touched) ||
                                errors.zip?.message.length > 0 || isSubmitting}
                            isLoading={isSubmitting}
                            controlSize={0}
                            offset={0}
                            padding={0}
                            buttonText={"Create"}
                            onClick={handleSubmit(onSubmit)} />
                        </BtnsContainer>
                    </SettingsStyledHeader>
                    <>
                        <SettingsBodyContainer>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <StyledSettingsRow>
                                    <Col xs={10} md={5}>
                                        <SettingsTitle>
                                            Super Admin Information
                                        </SettingsTitle>
                                        <Row style={{marginBottom: -15}}>
                                            <Col xs={6}>
                                                <GSPSLabelledInput
                                                    id="first_name"
                                                    controlType={"input"}
                                                    labelName={"First Name"}
                                                    isRequired={true}
                                                    register={register}
                                                    errors={errors} />
                                            </Col>
                                            <Col xs={6}>
                                                <GSPSLabelledInput
                                                    id="last_name"
                                                    controlType={"input"}
                                                    labelName={"Last Name"}
                                                    isRequired={true}
                                                    register={register}
                                                    errors={errors} />
                                            </Col>
                                        </Row>
                                        <GSPSLabelledInput
                                            id="email"
                                            controlType={"email"}
                                            labelName={"Email"}
                                            isRequired={true}
                                            register={register}
                                            patternType={PatternType.EMAIL}
                                            errors={errors} />
                                        {industries &&
                                            <GSPSLabelledSelectField
                                                labelName={"Industry Type"}
                                                inputID={"industry_type_id"}
                                                selectableOptions={industries}
                                                register={register}
                                                isRequired={true}
                                                errors={errors}
                                            />}
                                        {roles &&
                                            <GSPSLabelledSelectField
                                                labelName={"Role"}
                                                inputID={"role_id"}
                                                selectableOptions={roles}
                                                register={register}
                                                isRequired={true}
                                                errors={errors}
                                            />}
                                    </Col>
                                    <Col xs={10} md={5}>
                                        <SettingsTitle>
                                            Subscriber Information
                                        </SettingsTitle>
                                        <GSPSLabelledInput
                                            id="name"
                                            controlType={"input"}
                                            labelName={"Name"}
                                            isRequired={true}
                                            register={register}
                                            errors={errors} />
                                        <AddressForm
                                            id="address"
                                            register={register}
                                            errors={errors}
                                            setValue={setValue}
                                        />
                                        <GSPSLabelledInput
                                            id="website"
                                            controlType={"input"}
                                            labelName={"Website"}
                                            register={register}
                                            errors={errors} />
                                    </Col>
                                </StyledSettingsRow>
                            </Form>
                        </SettingsBodyContainer>
                    </>
                </SettingsStyledContainer>
            </>
            <Backdrop
                sx={{color: "#fff", zIndex: "555"}}
                open={isSubmitting}
                onClick={() => setIsSubmitting(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
CreateSubscriber.propTypes = {
    onNext: PropTypes.func.isRequired,
};
