import React, {useCallback, useEffect, useState} from "react";
import {DrawingManager, GoogleMap, Marker, OverlayView, Polygon, Polyline} from "@react-google-maps/api";
import {insertNotification} from "Utils/NotificationsUtils";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationCrosshairs} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

const containerStyle = {
    width: "100%",
    minHeight: "70vh",
    position: "relative",
};

export const GSPSGoogleMap = ({
    annotationAreas,
    annotationLines,
    annotationMarkers,
    saveIssue,
    saveLine,
    saveArea,
    currentLocation,
    setMapClicked,
    currentAnnotationShape,
    currentAuditType,
    auditsFilter = {"items": [], "severity": [], "lines": [], "areas": [], "default": true},
    handleDropDownResetDone,
    onLineClick,
    onIssueClick,
    onAreaClick,
    currentAuditTypeItem,
    updateIssue,
    isViewOnly = false,
    issuesSeveritiesWithColor,
    zoomLevel = 22,
    onTilesLoaded
}) => {
    const [currentDrawingMode, setCurrentDrawingMode] = useState(null);
    const [map, setMap] = useState(null);
    const [hoveredItem, setHoveredItem] = useState(null);
    const onItemMouseOver = (item) => {
        setHoveredItem(item);
    };
    const onItemMouseOut = () => {
        setHoveredItem(null);
    };
    const handleZoomChange = () => {
        setHoveredItem(null);
    };
    useEffect(() => {
        if (map) {
            map.addListener("zoom_changed", handleZoomChange);
        }
        return () => {
            if (map) {
                google.maps.event.clearListeners(map, "zoom_changed");
            }
        };
    }, [map]);
    useEffect(() => {
        setCurrentDrawingMode(
            (currentAnnotationShape?.query === "area" || currentAnnotationShape?.query === "line") && currentAuditType && currentAuditTypeItem ?
                currentAnnotationShape?.query === "area" ? "polygon" : "polyline" :
                null,
        );
    }, [currentAnnotationShape, currentAuditType, currentAuditTypeItem]);
    const onMapClick = useCallback(async (event) => {
        if (!currentAuditType || !currentAnnotationShape || !currentAuditTypeItem) {
            insertNotification("Error", "Please select Audit Type, Item Type and Item first", "error");
            return;
        }
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        if (currentAnnotationShape?.query === "item") {
            await saveIssue({lat, lng});
            handleDropDownResetDone();
            setMapClicked(true);
        }
    }, [currentAnnotationShape, saveIssue, handleDropDownResetDone, setMapClicked, currentAuditType, currentAuditTypeItem]);

    const createCustomIcon = (marker) => ({
        path: "M10,20a10,10 0 1,1 0,-20a10,10 0 1,1 0,20",
        fillColor: marker.audit_type_item.color,
        fillOpacity: 1,
        strokeColor: isViewOnly ?
            marker.severity_object?.color_code :
            issuesSeveritiesWithColor?.find((issueSeverity) =>
                issueSeverity.id === Number(marker.severity))?.color_code,
        strokeWeight: 3,
        scale: 1.5,
        labelOrigin: new google.maps.Point(10, 10),
    });
    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
    }, []);
    const onMarkerDragEnd = useCallback((event, issue) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const newIssue = {
            ...issue,
            address_lnglat: {...issue.address_lnglat, lat: lat, lng: lng},
            audit_type_item_id: issue.audit_type_item.id,
        };
        updateIssue(newIssue);
    }, [annotationMarkers]);

    const onDrawingComplete = async (shape) => {
        if (!currentAuditType || !currentAnnotationShape || !currentAuditTypeItem) {
            insertNotification("Error", "Please select Audit Type, Item Type and Item first", "error");
            shape.setMap(null);
            return;
        }
        const path = shape.getPath().getArray().map((point) => ({lat: point.lat(), lng: point.lng()}));
        if (shape.type === "polygon") {
            if (path.length <= 2) {
                insertNotification("Error", "An area must have more than two points.", "error");
                shape.setMap(null);
                return;
            }
            const areaInMeters = google.maps.geometry.spherical.computeArea(shape.getPath());
            const areaInSquareFeet = areaInMeters * 10.7639;
            await saveArea({path, areaInSquareFeet});
            shape.setMap(null);
            setCurrentDrawingMode(null);
            handleDropDownResetDone();
            setMapClicked(true);
        }
    };

    const getLabelPosition = (path) => {
        let latSum = 0;
        let lngSum = 0;
        path.forEach((point) => {
            latSum += point.lat;
            lngSum += point.lng;
        });
        const lat = latSum / path.length;
        const lng = lngSum / path.length;
        return {lat, lng};
    };
    const goToCurrentLocation = useCallback(() => {
        if (map) {
            const successCallback = (position) => {
                const {latitude, longitude} = position.coords;
                const newCenter = {lat: latitude, lng: longitude};
                map.panTo(newCenter);
                map.setZoom(zoomLevel);
            };
            const errorCallback = (error) => {
                insertNotification("Error", "Unable to detect your location. Enable location permission for the browser.", "error");
            };
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {maximumAge: 0, timeout: 5000, enableHighAccuracy: true});
        }
    }, [map, zoomLevel]);
    const onPolylineComplete = async (polyline) => {
        if (!currentAuditType || !currentAnnotationShape || !currentAuditTypeItem) {
            insertNotification("Error", "Please select Audit Type, Item Type and Item first", "error");
            polyline.setMap(null);
            return;
        }
        const path = polyline.getPath().getArray().map((point) => ({lat: point.lat(), lng: point.lng()}));
        if (path.length === 2) {
            const [point1, point2] = path;
            const linePath = {lat1: point1.lat, lng1: point1.lng, lat2: point2.lat, lng2: point2.lng};
            const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(
                new google.maps.LatLng(point1.lat, point1.lng),
                new google.maps.LatLng(point2.lat, point2.lng),
            );
            const distanceInFeet = distanceInMeters * 3.28084;
            await saveLine(linePath, distanceInFeet);
            polyline.setMap(null);
            setCurrentDrawingMode(null);
            handleDropDownResetDone();
            setMapClicked(true);
        } else {
            insertNotification("Error", "Only two points are allowed for a lineٍ.", "error");
            polyline.setMap(null);
        }
    };
    // const clusterStyles = [
    //     {
    //         url: "/cluster.png",
    //         backgroundColor: "red",
    //         height: 40,
    //         width: 40,
    //         textColor: "white",
    //         textSize: 12,
    //         anchorText: [0, 0],
    //     },
    // ];
    return (
        <div style={containerStyle}>
            <Button
                onClick={goToCurrentLocation}
                style={{
                    position: "absolute",
                    bottom: "150px",
                    right: "5px",
                    zIndex: 5,
                    border: "none",
                    backgroundColor: "white",
                    padding: "4px",
                    width: "20",
                    height: "20",
                    cursor: "pointer",
                    fontSize: "15px",
                    color: "black",
                }}
            >
                <FontAwesomeIcon icon={faLocationCrosshairs} />
            </Button>
            <GoogleMap
                onTilesLoaded={onTilesLoaded}
                id={"google-map"}
                mapContainerStyle={containerStyle}
                center={currentLocation}
                zoom={zoomLevel}
                onLoad={onLoad}
                onUnmount={() => {
                    setMap(null);
                }}
                options={{
                    streetViewControl: true,
                    mapTypeId: "satellite",
                    fullscreenControl: true,
                    zoomControl: true,
                    controlSize: 24,
                    fullscreenControlOptions: {position: google.maps.ControlPosition.BOTTOM_RIGHT},
                    zoomControlOptions: true,
                    rotateControl: true,
                    rotateControlOptions: {position: google.maps.ControlPosition.BOTTOM_LEFT},
                    draggableCursor: "crosshair",
                }}
                onClick={!isViewOnly && onMapClick}
            >

                {/* comment cluster code for  to easy retrieve */}
                {/* {annotationMarkers.length > 0 && auditsFilter.items ? (
                        <MarkerClusterer
                            options={{
                                styles: clusterStyles,
                                maxZoom: 15,
                                gridSize: 30,
                                minimumClusterSize: 2,
                            }}
                        >
                            {(clusterer) =>
                                annotationMarkers.map((marker, index) => {
                                    return ((auditsFilter.items.includes(marker.audit_type_item.audit_type.name) || auditsFilter.default) &&
                                    <Marker
                                        key={index}
                                        position={{
                                            lat: Number(marker.address_lnglat.lat),
                                            lng: Number(marker.address_lnglat.lng),
                                        }}
                                        draggable={!isViewOnly}
                                        onDragEnd={(event) => onMarkerDragEnd(event, marker)}
                                        onClick={() => onIssueClick(marker)}
                                        onMouseOver={() => onItemMouseOver(marker)}
                                        onMouseOut={onItemMouseOut}
                                        icon={createCustomIcon(marker)}
                                        label={{
                                            text: String(index + 1),
                                            color: "black",
                                            fontWeight: "bold",
                                            fontSize: "21px",
                                        }}
                                        clusterer={clusterer}
                                    />
                                    );
                                })
                            }
                        </MarkerClusterer>
                    ) : null} */}
                {annotationMarkers.length > 0 &&
                    auditsFilter.items ? annotationMarkers.map((marker, index) => {
                        return (((auditsFilter.items?.includes(marker.audit_type_item.name?.trim()) &&
                            auditsFilter.severity?.includes(marker.severity_object.name?.trim())) ||
                            auditsFilter.default) && <Marker
                                key={index}
                                position={{lat: Number(marker.address_lnglat.lat), lng: Number(marker.address_lnglat.lng)}}
                                draggable={!isViewOnly}
                                onDragEnd={(event) => onMarkerDragEnd(event, marker)}
                                onClick={() => {
                                    onIssueClick(marker);
                                }}
                                icon={createCustomIcon(marker)}
                                label={{
                                    text: String(index + 1),
                                    color: "black",
                                    fontWeight: "light",
                                    fontSize: "18px",
                                }}
                            />);
                    }) :
                    null}
                {hoveredItem && (
                    <OverlayView
                        position={hoveredItem?.position ? hoveredItem.position : {
                            lat: Number(hoveredItem.address_lnglat.lat),
                            lng: Number(hoveredItem.address_lnglat.lng),
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div
                            style={{
                                background: "#333",
                                color: "white",
                                borderRadius: "4px",
                                padding: "6px 10px",
                                whiteSpace: "nowrap",
                                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.5)",
                                display: "flex",
                                alignItems: "center",
                                width: "fit-content",
                                minWidth: "100px",
                                height: "50px",
                                opacity: "90%",
                                fontSize: 14,
                                fontWeight: "light",
                                transform: "translate(30%, -100%)",
                            }}
                        >
                            <div>
                                <strong>{hoveredItem.audit_type_item.name}</strong> <br />
                                {!hoveredItem?.position && (
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <div
                                            style={{
                                                width: "8px",
                                                height: "8px",
                                                borderRadius: "50%",
                                                backgroundColor: issuesSeveritiesWithColor?.find(
                                                    (severity) => severity.id === Number(hoveredItem.severity),
                                                )?.color_code,
                                                marginRight: "6px",
                                            }}
                                        />
                                        {
                                            issuesSeveritiesWithColor?.find(
                                                (severity) => severity.id === Number(hoveredItem.severity),
                                            )?.name
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </OverlayView>
                )}

                {annotationAreas.length > 0 &&
                    auditsFilter.areas ? annotationAreas.map((area, index) => {
                        const path = area.vertices_address_lnglat.map((path) =>
                            ({lat: Number(path.lat), lng: Number(path.lng)})) ?? {};
                        return (
                            (auditsFilter.areas.includes(area.audit_type_item.name?.trim()) || auditsFilter.default) && <React.Fragment key={area.index}>
                                <Polygon
                                    paths={path}
                                    options={{
                                        fillColor: `${area.audit_type_item.color ?? "blue"}`,
                                        fillOpacity: 0.7,
                                        strokeColor: "blue",
                                        strokeOpacity: 1,
                                        strokeWeight: 0,
                                    }}
                                />
                                <OverlayView
                                    position={getLabelPosition(path)}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                >
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onAreaClick(area);
                                        }}
                                        onMouseOver={() => onItemMouseOver({...area, position: getLabelPosition(path)})}
                                        onMouseOut={onItemMouseOut}
                                        style={{
                                            position: "absolute",
                                            background: "#F0F0F0",
                                            border: "4px solid #02A783",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "32px",
                                            height: "32px",
                                            fontSize: "21px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {index + 1}
                                    </div>
                                </OverlayView>
                            </React.Fragment>
                        );
                    }) : null}
                {annotationLines.length > 0 &&
                    auditsFilter.lines ? annotationLines.map((line, index) => {
                        // eslint-disable-next-line camelcase
                        const {address_lnglat1, address_lnglat2} = line;
                        const path = [
                            {lat: Number(address_lnglat1.lat), lng: Number(address_lnglat1.lng)},
                            {lat: Number(address_lnglat2.lat), lng: Number(address_lnglat2.lng)},
                        ];
                        return (
                            (auditsFilter.lines.includes(line.audit_type_item.name?.trim()) || auditsFilter.default) && <React.Fragment key={index}>
                                <Polyline
                                    onClick={() => {
                                    }}
                                    path={path}
                                    value={line}
                                    options={{
                                        strokeColor: `${line.audit_type_item.color ?? "red"}`,
                                        strokeOpacity: 1,
                                        strokeWeight: 2,
                                    }}
                                />
                                <OverlayView
                                    position={getLabelPosition(path)}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                >
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onLineClick(line);
                                        }}
                                        onMouseOver={() => onItemMouseOver({...line, position: getLabelPosition(path)})}
                                        onMouseOut={onItemMouseOut}
                                        style={{
                                            position: "absolute",
                                            background: "#F0F0F0",
                                            border: "4px solid black",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "32px",
                                            height: "32px",
                                            fontSize: "21px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {index + 1}
                                    </div>
                                </OverlayView>
                            </React.Fragment>
                        );
                    }) : null}

                <DrawingManager
                    drawingMode={currentDrawingMode}
                    options={{
                        drawingControl: false,
                        polylineOptions: {
                            strokeColor: "red",
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            editable: true,
                        },
                        polygonOptions: {
                            strokeColor: "blue",
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            editable: true,
                        },
                    }}
                    onPolygonComplete={(polygon) => {
                        polygon.type = "polygon";
                        onDrawingComplete(polygon);
                    }}
                    onPolylineComplete={onPolylineComplete}
                />
            </GoogleMap>
        </div>

    );
};
GSPSGoogleMap.propTypes = {
    annotationAreas: PropTypes.array.isRequired,
    annotationLines: PropTypes.array.isRequired,
    annotationMarkers: PropTypes.array.isRequired,
    saveIssue: PropTypes.func.isRequired,
    saveLine: PropTypes.func.isRequired,
    saveArea: PropTypes.func.isRequired,
    setMapClicked: PropTypes.func.isRequired,
    currentAnnotationShape: PropTypes.object.isRequired,
    currentAuditType: PropTypes.object.isRequired,
    auditsFilter: PropTypes.object.isRequired,
    handleDropDownResetDone: PropTypes.func.isRequired,
    onLineClick: PropTypes.func.isRequired,
    currentAuditTypeItem: PropTypes.object.isRequired,
    onIssueClick: PropTypes.func,
    onAreaClick: PropTypes.func,
    currentLocation: PropTypes.object,
    updateIssue: PropTypes.func.isRequired,
    isViewOnly: PropTypes.bool,
    issuesSeveritiesWithColor: PropTypes.array,
    zoomLevel: PropTypes.number,
};
