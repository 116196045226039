import { useState } from "react"

export const GSPSCheckBox = ({
    label,
    onChangeCB,
    id,
    defaultValue = false,
}) => {
    const [checked, setChecked] = useState(defaultValue);
    const handleCheckOption = (checked, id) => {
        setChecked(checked);
        if (onChangeCB) {
            onChangeCB(checked, id);
        }
    };
    return (<><div className="col-12">
        <div className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                id={id}
                checked={checked}
                onChange={(e) => { handleCheckOption(e.target.checked, e.target.id) }}
            />
            <label className="form-check-label body-md" htmlFor="select-all">
                {label}
            </label>
        </div>
    </div></>)
}