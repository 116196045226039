import GetAppIcon from '@mui/icons-material/GetApp';
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import {CircularProgress} from '@mui/material';

const GSPSExportButton = ({options = [], loading = false}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return <>
        <Button
            variant="outlined"
            className="cta-btn float-end mb-3 z-2"
            id='exportBtn'
            aria-controls={menuOpen ? 'menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
            onClick={handleClick}
            endIcon={loading ? <CircularProgress size={"15px"} color="inherit" /> : <GetAppIcon />}
            disabled={loading}
        >
            <span className={`${loading ? "disabledText" : ""}`}>
                Export
            </span>
        </Button>

        <Menu
            id="menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'exportBtn',
            }}
        >
            {
                options.map((option) => {
                    return <MenuItem
                        onClick={() => {
                            option.onClick();
                            handleClose();
                        }
                        }
                    >
                        {option.text}
                    </MenuItem>
                })
            }
        </Menu>
    </>
}

GSPSExportButton.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
        })
    ),
    loading: PropTypes.bool
};

export default GSPSExportButton