import React, {useState, useEffect} from "react";
import {GSPSLabelledInput, PatternType} from "Components/Common/GSPSLabelledInput";
import {
    fetchSelectionOptions,
    makeGetRequest,
} from "Services/ServicesUtils";
import {API_SUBSCRIBER} from "Models/Subscriber/SubscriberEndpoints";
import {Col, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {GSPSCancelButton} from "Components/Common/GSPSCancelButton";
import {insertNotification} from "Utils/NotificationsUtils";
import {AddressForm} from "Components/Common/AddressForm";
import {
    SettingsStyledContainer,
    SettingsStyledHeader, BtnsContainer,
    StyledSettingsRow, SettingsTitle,
    SettingsBodyContainer,
} from "Pages/Subscriber/Subscriber.styles";

import {useNavigate} from "react-router-dom";
import GSPSBreadCrumbs from "../../Components/Common/GSPSBreadCrumbs";
import {Backdrop, CircularProgress} from "@mui/material";
import {API_USERS} from "Services/Endpoints";
import PropTypes from "prop-types";
import {GSPSLabelledSelectField} from "Components/Common/GSPSLabelledSelectField";

export const SignupForm = ({onNext}) => {
    const {handleSubmit, register, setValue, formState: {isDirty, touched, errors}} = useForm();
    const [industries, setIndustries] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchSelectionOptions(API_SUBSCRIBER.industries, setIndustries, "name");
    }, []);

    const onSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            const data = await makeGetRequest(API_USERS.userExists + `?email=${values.email}`);
            if (!data.exists) {
                values.industry_id = values.industry;
                delete values.industry;
                onNext(values);
            } else {
                throw new Error("This Email is already subscribed, if you are the owner, please sign in.");
            }
        } catch (e) {
            insertNotification("Error", `${e.message}`, "error");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <GSPSBreadCrumbs staticCrumb="subscriber" />
            <>
                <SettingsStyledContainer>
                    <SettingsStyledHeader>
                        <SettingsTitle>
                            Subscriber Information
                        </SettingsTitle>
                        <BtnsContainer>
                            <GSPSCancelButton
                                controlSize={0}
                                offset={0}
                                buttonText={"Back"}
                                secondaryBtn={true}
                                onClick={() => navigate("/plans")}
                            />
                            <GSPSSubmitButton isDisabled={(!isDirty && touched) ||
                                errors.zip?.message.length > 0 || isSubmitting}
                            isLoading={isSubmitting}
                            controlSize={0}
                            offset={0}
                            padding={0}
                            buttonText={"Next"}
                            onClick={handleSubmit(onSubmit)} />
                        </BtnsContainer>
                    </SettingsStyledHeader>
                    <>
                        <SettingsBodyContainer>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <StyledSettingsRow>
                                    <Col xs={10} md={5}>
                                        <GSPSLabelledInput
                                            id="name"
                                            controlType={"input"}
                                            labelName={"Name"}
                                            isRequired={true}
                                            register={register}
                                            errors={errors} />
                                        <GSPSLabelledInput
                                            id="email"
                                            controlType={"email"}
                                            labelName={"Email"}
                                            isRequired={true}
                                            register={register}
                                            patternType={PatternType.EMAIL}
                                            errors={errors} />
                                        <AddressForm
                                            id="address"
                                            register={register}
                                            errors={errors}
                                            setValue={setValue}
                                        />
                                        <GSPSLabelledInput
                                            id="website"
                                            controlType={"input"}
                                            labelName={"Website"}
                                            register={register}
                                            errors={errors} />

                                        <GSPSLabelledInput
                                            id="phone"
                                            controlType={"input"}
                                            labelName={"Phone"}
                                            register={register}
                                            errors={errors}
                                            isPhone={true} />
                                        {industries &&
                                            <GSPSLabelledSelectField
                                                labelName={"Industry"}
                                                inputID={"industry"}
                                                selectableOptions={industries}
                                                register={register}
                                                errors={errors}
                                            />}
                                    </Col>

                                    <Col xs={10} md={5}>

                                    </Col>
                                </StyledSettingsRow>
                            </Form>
                        </SettingsBodyContainer>
                    </>
                </SettingsStyledContainer>
            </>
            <Backdrop
                sx={{color: "#fff", zIndex: "555"}}
                open={isSubmitting}
                onClick={() => setIsSubmitting(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
SignupForm.propTypes = {
    onNext: PropTypes.func.isRequired,
};
