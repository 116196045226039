import { GSPSCheckBox } from "Components/Common/GSPSCheckBox";
import { UserContext } from "context/UserContext";
import { useContext, useEffect, useState } from "react"
import { Form } from "react-bootstrap";
import { API_DASHBOARD_COLUMNS } from "Services/Endpoints"
import { makeGetRequest } from "Services/ServicesUtils"

export const GSPSDashboardColumnsSelect = ({ setSelectedDashboardCols }) => {
    const [dashboardCols, setDashboardCols] = useState([])
    const { user: { default_dashboard_columns: userCurrentDashboardCols } = {} } = useContext(UserContext);
    useEffect(() => {
        makeGetRequest(API_DASHBOARD_COLUMNS.dashboardColumns + "?no_page")
            .then((res) => {
                setDashboardCols(res);
            })
    }, [])

    useEffect(() => {
        setSelectedDashboardCols(userCurrentDashboardCols);
    }, [userCurrentDashboardCols])

    const handleSelection = (checked, name) => {
        setSelectedDashboardCols((prev) => {
            if (checked) {
                return [...prev, name];
            }
            else {
                return prev.filter((col) => col !== name);
            }
        });
    }

    return (
        <>
            <Form.Label>Default Dashboard Columns</Form.Label>
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
            }}>
                {dashboardCols?.map((col) => {
                    const isChecked = userCurrentDashboardCols?.includes(col.name);
                    return (
                        <div key={col.id}>
                            <GSPSCheckBox
                                id={col.name}
                                label={col.name}
                                defaultValue={isChecked}
                                onChangeCB={handleSelection} />
                        </div>
                    )
                })}
            </div></>
    )
}