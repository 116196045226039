import React from "react";
import {Login} from "Pages/Login/Login";
import {ForgotPassword} from "Pages/Login/ForgotPassword";
import {ResetPassword} from "Pages/Login/ResetPassword";
import {AuditCreator} from "Pages/Audits/AuditCreator";
import {Audits} from "Pages/Audits/Audits";
import {AuditViewer} from "Pages/Audits/AuditViewer";
import {Subscriber} from "Pages/Subscriber/Subscriber";
import {Accounts} from "Pages/Accounts/Accounts";
import {Account} from "Pages/Accounts/Account";
import {Location} from "Pages/Location/Location";
import {Home} from "Pages/Home/Home";
import {LocationsManager} from "Pages/Location/LocationsManager";
import {AddLocation} from "Pages/Location/AddLocation";
import {Users} from "Pages/Users/Users";
import {PageNotFound} from "Pages/PageNotFound";
import {AccountsManager} from "Pages/Accounts/AccountsManager";
import {AddAccount} from "Pages/Accounts/AddAccount";
import {UserEditor} from "Pages/Users/UserEditor";
import {UserCreator} from "Pages/Users/UserCreator";
import {Report} from "Pages/Report/Report";
import {PublicAuditViewer} from "Pages/Audits/PublicAuditViewer";
import {Locations} from "Pages/Location/Locations";
import {ActivityLogs} from "Pages/History/ActivityLogs";
import {Plans} from "Pages/Plans/Plans";
import {Checkout} from "Pages/Checkout/Checkout";
import {Return} from "Pages/Checkout/Return";
import AuditLogs from "Pages/Audits/AuditLogs";
import {CreateSubscriber} from "Pages/Admin/Subscribers/CreateSubscriber";
import {Profile} from "Pages/UserProfile/Profile";

export const getPathElementMap = (resetCurrentUserSession) => [
    {path: "/", element: <Audits />},
    {path: "/dashboard", element: <Audits />},
    {path: "/profile/", element: <Profile />},
    {path: "/subscribers/:subscriberID", element: <Subscriber />},
    {path: "/accounts/", element: <Accounts />},
    {path: "/accounts/:accountID", element: <Account />},
    {path: "/accounts/manage", element: <AccountsManager />},
    {path: "/accounts/add", element: <AddAccount />},
    {path: "/locations/", element: <Locations />},
    {path: "/locations/:locationID", element: <Location />},
    {path: "/locations/manage", element: <LocationsManager />},
    {path: "/report", element: <Report />},
    {path: "/locations/add", element: <AddLocation />},
    {path: "/audits", element: <Audits />},
    {path: "/audits/:auditID", element: <AuditViewer />},
    {path: "/audits/creator", element: <AuditCreator />},
    {path: "/audits/logs/:auditID", element: <AuditLogs />},
    {path: "/users/", element: <Users />},
    {path: "/users/creator", element: <UserCreator />},
    {path: "/users/:userID", element: <UserEditor />},
    {path: "*", element: <PageNotFound />},
    {path: "/public-audits/:auditID", element: <PublicAuditViewer />},
    {path: "/plans", element: <Plans />},
    {path: "/history/", element: <ActivityLogs />},
    {path: "/reset-password", element: <ResetPassword resetCurrentUserSession={resetCurrentUserSession} />},
    {path: "/return", element: <Return />},
    {path: "/create-subscriber", element: <CreateSubscriber />},
];

export const getUnauthenticatedPathElementMap = (setHasValidRefreshToken) => [
    // {path: "/", element: <Home />}, //We put this back in when we refactor the Home page.
    {path: "/login", element: <Login setHasValidRefreshToken={setHasValidRefreshToken} />},
    {path: "/forgot-password", element: <ForgotPassword />},
    {path: "/reset-password", element: <ResetPassword />},
    {path: "*", element: <Login setHasValidRefreshToken={setHasValidRefreshToken} />},
    {path: "/public-audits/:auditID", element: <PublicAuditViewer />},
    {path: "/plans", element: <Plans />},
    {path: "/checkout/:priceLookupKey", element: <Checkout />},
    {path: "/return", element: <Return />},
];

export const unRedirectionPaths = [
    "/plans",
    "/reset-password",
    "/return",
];
