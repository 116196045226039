import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { useForm } from "react-hook-form";
import { GSPSLabelledInput, PatternType } from "Components/Common/GSPSLabelledInput";
import { makeGetRequest, makePostRequest } from "Services/ServicesUtils";
import { API_AUDITS } from "Models/Audit/AuditEndpoints";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { GSPSSubmitButton } from "Components/Common/GSPSSubmitButton";
import { GSPSLabelledSelectField } from "Components/Common/GSPSLabelledSelectField";
import { API_EMAIL_TEMPLATES } from "Models/Email Templates/TemplatesEndpoints";
import { insertNotification } from "Utils/NotificationsUtils";
import { UserContext } from "context/UserContext";
export const SendReportForm = ({
    onSent
}) => {
    const { user: currentUser } = useContext(UserContext);
    const { register, setValue, getValues, handleSubmit, formState: { errors } } = useForm();
    const [templates, setTemplates] = useState([]);
    const [selectableTemplateOptions, setSelectableTemplateOptions] = useState([]);
    const [emailsValid, setEmailsValid] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { auditID } = useParams();
    const [inputValue, setInputValue] = useState("");
    const [body, setBody] = useState("");
    const [chips, setChips] = useState([]);

    useEffect(() => {
        makeGetRequest(API_EMAIL_TEMPLATES.templates + "?no_page=true")
            .then((response) => {
                setTemplates(response);
                const selectableOptions = response.map((template) => {
                    return {
                        key: template.id,
                        value: template.id,
                        display_text: template.name,
                    };
                });
                setSelectableTemplateOptions(selectableOptions);
            });
    }, []);

    const handleTemplateChange = (e) => {
        const selectedTemplateId = e.target.value;
        const selectedTemplate = templates.find((template) => template.id === Number(selectedTemplateId));
        selectedTemplate && setBody(selectedTemplate.body);
        selectedTemplate && setValue("subject", selectedTemplate.subject);
    };

    useEffect(() => {
        setEmailsValid(chips.every(validateEmail));
    }, [chips]);

    useEffect(() => {
        setValue("reply_to", currentUser.email);
    }, [currentUser]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChipSaving = (event) => {
        if (event.key === " " || event.type == 'blur') {
            event.preventDefault();
            const value = inputValue.trim().replace(/\.$/, "");
            if (value && !chips.includes(value)) {
                setChips((prev) => [...prev, value]);
                setInputValue("");
            }
        }
    };

    const onSubmit = (e) => {
        if (isSending) return;
        setIsSending(true);
        const data = {
            ...getValues(),
            link: window.location.origin + "/public-audits/" + auditID,
            emails: chips,
            body: body,
            audit_id: auditID,
        };
        makePostRequest(API_AUDITS.AuditEmail, data)
            .then((res) => {
                insertNotification("Success", res.message, "success");
                onSent();
            })
            .catch((e) => {
                e.then((error) => {
                    insertNotification("Error", JSON.stringify(error), "error");
                });
            })
            .finally(() => {
                setIsSending(false);
                setIsSubmitted(true);
            });
    };
    return (
        <>
            <Container className="p-3">
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <Row>
                        <Col xs={ 12 } md={ 5 }>
                            <GSPSLabelledSelectField
                                inputID={ "email_template" }
                                labelName="Template"
                                isBold={ true }
                                isRequired={ false }
                                onChangeCB={ handleTemplateChange }
                                selectableOptions={ selectableTemplateOptions }
                            />
                        </Col>

                        <Col xs={ 12 } md={ 12 }>
                            <Autocomplete
                                multiple
                                freeSolo
                                options={ [] }
                                value={ chips }
                                onChange={ (event, newValue) => setChips(newValue) }
                                inputValue={ inputValue }
                                onInputChange={ (event, newInputValue) => setInputValue(newInputValue) }
                                renderTags={ (value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={ index }
                                            variant="outlined"
                                            label={ option }
                                            { ...getTagProps({ index }) }
                                            sx={
                                                validateEmail(option) ?
                                                    {} :
                                                    { borderColor: "red", color: "red" }
                                            }
                                        />
                                    ))
                                }
                                renderInput={ (params) => (
                                    <TextField
                                        { ...params }
                                        variant="outlined"
                                        label="To"
                                        placeholder="Emails"
                                        size="small"
                                        onKeyDown={ handleChipSaving }
                                        onBlur={ handleChipSaving }
                                        sx={ {
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused": {
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#ced4da",
                                                    },
                                                },
                                            },
                                            "& .MuiInputLabel-outlined": {
                                                color: "black",
                                            },
                                        } }
                                    />
                                ) }
                            />
                        </Col>

                        <Col xs={ 12 }>
                            <Row>
                                <Col xs={ 12 } md={ 6 }>
                                    <GSPSLabelledInput
                                        id="cc"
                                        controlType={ "input" }
                                        placeHolder="Cc"
                                        register={ register }
                                        patternType={ PatternType.EMAIL }
                                        errors={ errors }
                                        hiddenLabel={ true }
                                    />
                                </Col>
                                <Col xs={ 12 } md={ 6 }>
                                    <GSPSLabelledInput
                                        id="bcc"
                                        controlType={ "input" }
                                        placeHolder="Bcc"
                                        register={ register }
                                        patternType={ PatternType.EMAIL }
                                        errors={ errors }
                                        hiddenLabel={ true }
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={ 12 }>
                            <Row>
                                <Col xs={ 12 } md={ 6 }>
                                    <GSPSLabelledInput
                                        id="reply_to"
                                        controlType={ "input" }
                                        placeHolder="Reply To"
                                        register={ register }
                                        hiddenLabel={ true }
                                        patternType={ PatternType.EMAIL }
                                        isRequired={ true }
                                        errors={ errors }
                                    />
                                </Col>
                                <Col xs={ 12 } md={ 6 }>
                                    <GSPSLabelledInput
                                        id="display_name"
                                        controlType={ "input" }
                                        placeHolder="Display Name"
                                        register={ register }
                                        hiddenLabel={ true }
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={ 12 } md={ 12 }>
                            <GSPSLabelledInput
                                id="link_display_name"
                                controlType={ "input" }
                                placeHolder="Link Display Name"
                                register={ register }
                                errors={ errors }
                                hiddenLabel={ true }
                            />
                        </Col>
                        <Col xs={ 12 } md={ 12 }>
                            <GSPSLabelledInput
                                id="subject"
                                controlType={ "input" }
                                placeHolder="Subject"
                                errors={ errors }
                                register={ register }
                                isRequired={ true }
                                hiddenLabel={ true }
                            />
                        </Col>
                        <Col xs={ 12 } md={ 12 }>
                            <ReactQuill value={ body } placeholder="Body" onChange={ setBody } />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={ 12 } className="d-flex justify-content-end mt-4">
                            <GSPSSubmitButton
                                isDisabled={ !emailsValid || chips.length === 0 || isSending || isSubmitted }
                                isLoading={ isSending }
                                controlSize={ 12 }
                                offset={ 0 }
                                className="flex-none"
                                buttonText={ "Send" }
                            />
                        </Col>
                    </Row>
                </form>
            </Container>
        </>
    );
};

SendReportForm.propTypes = {
};
