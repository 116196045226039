import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { makeFormDataUpdateRequest } from "Services/ServicesUtils";
import { Form } from "react-bootstrap";
import { GSPSSubmitButton } from "Components/Common/GSPSSubmitButton";
import { API_ACCOUNTS } from "Models/Account/AccountEndpoints";
import { insertNotification } from "Utils/NotificationsUtils";
import { AccountForm } from "Components/Accounts/AccountForm";
import { GSPSCancelButton } from "../../Components/Common/GSPSCancelButton";
import { GSPSPageWrapper, GSPSPopUpHeader, GSPSPopUpFooter } from "Components/Common/GSPSStyledContainers.styles";
import { useAddAccountMutation } from "api/AccountTableAPI";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { fetchAllData } from "app/reducers/auditDataSlice";

export const AddAccount = ({ show, setShow }) => {
    const { handleSubmit, register, setValue, formState: { isDirty, touched, errors } } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasLogo, setHasLogo] = useState(false);
    const [addAccount] = useAddAccountMutation();
    const dispatch = useDispatch();

    const onSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            await submitForm(values);
        } catch (e) {
            console.error("Something is wrong with saving account details", e.toString());
            insertNotification("Error", `can't add Account: ${e.message}`, "error");
        } finally {
            setIsSubmitting(false);
        }
    };

    const submitForm = async (values) => {
        const { logo, ...otherValues } = values;
        const data = {
            ...otherValues,
            account_type_id: values.account_type_id,
            account_admins_ids: values.account_admins_ids === "" ? [] : [values.account_admins_ids],
            credits: values.credits === "" ? 0 : values.credits,
        };
        try {
            const createdAccount = await addAccount(data).unwrap();
            await submitImage(values, createdAccount.id);

            dispatch(fetchAllData());
            insertNotification("Success", `Account ${createdAccount.name} has been created ..`, "success");
            setShow(false);
        } catch (error) {
            if (error?.data?.address) {
                insertNotification("Error", "Invalid address. Please select a valid address from the dropdown.", "error");
            } else {
                const failureReason = JSON.stringify(error.data);
                insertNotification("Error", `Can't create account: ${failureReason}`, "error");
            }
        }
    };

    const submitImage = async (values, accountId) => {
        if (hasLogo) {
            const formData = new FormData();
            formData.append("logo", values.logo[0]);
            return makeFormDataUpdateRequest(API_ACCOUNTS.accountLogo(accountId), formData)
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    return (
        <>
            <GSPSPageWrapper className="show" role="dialog"
                show={show}
                centered
                size={"lg"}
                container={document.querySelector("#root .App")}
            >
                <GSPSPopUpHeader className="subtitle-lg">
                    Add New Account
                </GSPSPopUpHeader>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <AccountForm
                        errors={errors}
                        register={register}
                        setImageValue={setValue}
                        setValue={setValue}
                        setHasLogo={setHasLogo}
                    />
                    <GSPSPopUpFooter>
                        <GSPSCancelButton
                            controlSize={2}
                            onClick={(event) => {
                                event.preventDefault();
                                setShow(false);
                            }}
                            buttonText={"Cancel"} />

                        <GSPSSubmitButton isDisabled={(!isDirty && touched) ||
                            errors.zip?.message.length > 0 || isSubmitting}
                            isLoading={isSubmitting}
                            controlSize={2}
                            buttonText={"Save"} />
                    </GSPSPopUpFooter>
                </Form>
            </GSPSPageWrapper>
        </>
    );
};
AddAccount.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
};
