import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {useForm} from "react-hook-form";
import {Form, Container, Row, Col} from "react-bootstrap";
import {makeFormDataUpdateRequest, makePatchRequest, makeUpdateRequest} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {insertNotification} from "Utils/NotificationsUtils";
import {compressImage, compressVideo} from "Utils/helpers";
import {GSPSLabelledSelectFieldWithIcon} from "Components/Common/GSPSLabelledSelectFieldWithIcon";
import {GSPSLabelledSelectField} from "Components/Common/GSPSLabelledSelectField";
import {DimensionsStyledContainer} from "Components/Common/GSPSLabelledSelectFieldWithIcon.styles";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {GSPSTextArea} from "Components/Common/GSPSTextAreaInput";
import {AuditImages, AuditVideos} from "Pages/Audits/AuditMedia";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {GSPSPageWrapper, GSPSPopUpHeader, GSPSPopUpFooter, GSPSModalStyle} from "../Common/GSPSStyledContainers.styles";
import {GSPSCancelButton} from "../Common/GSPSCancelButton";
import {FFmpeg} from "@ffmpeg/ffmpeg";
import {CircularProgress, FormControl, Modal, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useFlag} from "@unleash/proxy-client-react";

const ffmpeg = new FFmpeg();

const GSPSAnnotationModal = ({
    isOpen,
    onClose,
    isViewOnly = false,
    clickedItem,
    audit,
    deleteMedia,
    updateItem,
    deleteItem,
    type,
    issuesSeverities,
    typeItems,
    replaceTypeItemFilter,
}) => {
    if (!isOpen) return null;
    const videoCompressionFeature = useFlag("FE_Video_Compression");
    const {handleSubmit, register, setValue, reset, formState: {isDirty, touched, errors}} = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [isVideoCompressed, setIsVideoCompressed] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [availableTypeItems, setAvailableTypeItems] = useState([]);
    const [item, setItem] = useState(clickedItem);
    const [deletedMedia, SetDeletedMedia] = useState([]);

    const auditsFilterMap = {
        area: "Areas",
        issue: "Items",
        line: "Lines",
    }

    const updateElementMedia = (index, mediaValue, elementType, mediaType) => {
        setItem((prev) => {
            const newData = {...prev};
            const list = newData[type + "_" + mediaType] || [];
            if (index >= list.length) {
                const newMedia = {};
                if (mediaType === "videos") {
                    newMedia.video_value = mediaValue[0];
                } else {
                    newMedia.mediaValue = mediaValue[0];
                }
                newData[type + "_" + mediaType] = [...list, newMedia];
            } else if (index < list.length) {
                newData[type + "_" + mediaType] = list.map((img, i) => {
                    if (i == index) {
                        if (mediaType === "videos") {
                            return {...img, video_value: mediaValue[0]};
                        }
                        return {...img, mediaValue: mediaValue[0]};
                    }
                    return img;
                });
            }
            return newData;
        });
    };
    const updateElementImages = (rowId, newRowImages, elementType) => {
        updateElementMedia(rowId, newRowImages, elementType, "images");
    };
    const updateElementVideos = (rowId, newRowImages, elementType) => {
        updateElementMedia(rowId, newRowImages, elementType, "videos");
    };

    const handleDelete = (media, index, mediaType) => {
        SetDeletedMedia((prev) => [...prev, media]);
        setItem((prev) => {
            const newData = {...prev};

            const list = newData[type + "_" + mediaType + "s"] || [];
            if (index < list.length) {
                newData[type + "_" + mediaType + "s"] = list.filter((_, i) => i != index);
            }
            return newData;
        });
    };
    const filterItems = (annotationType, auditTypeId) => {
        const filtered = typeItems.filter((item) =>
            item.annotation_type === annotationType && item.audit_type.id === auditTypeId).map((item) => ({
                id: item.id,
                name: item.name,
                color_code: item.color,
            }));
        setAvailableTypeItems(filtered);
    };
    useEffect(() => {
        if (clickedItem) {
            if (!isViewOnly) {
                if (type === "issue") {
                    setValue("issue_severity", clickedItem.severity);
                }
                setValue("audit_type_item_id", clickedItem.audit_type_item.id);
                setValue("description", clickedItem.description);
                setValue("notes", clickedItem.notes);
            } else {
                setAvailableTypeItems([{
                    id: clickedItem.audit_type_item.id,
                    name: clickedItem.audit_type_item.name,
                    color_code: clickedItem.audit_type_item.color,
                }]);
                setValue("audit_type_item_id", clickedItem.audit_type_item.id);
            }
        }
    }, [clickedItem, availableTypeItems, issuesSeverities, type]);

    useEffect(() => {
        setItem(clickedItem);
        if (!isViewOnly) {
            filterItems(type, clickedItem?.audit_type_item?.audit_type?.id);
        }
    }, [clickedItem, type]);
    if (!isOpen && clickedItem?.id === item?.id) return null;

    const onSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            await submitForm(values);
            onClose();
        } catch (e) {
            insertNotification("Error", `Can't add audit ${e}. Reason: create the audit first`, "error");
        } finally {
            setIsSubmitting(false);
        }
    };

    const HandleClose = () => {
        SetDeletedMedia([]);
        setItem(clickedItem);
        onClose();
        reset();
    };
    const submitForm = async (values) => {
        const addedAuditTypeItem = typeItems.find((item) => item.id === values.audit_type_item_id);
        if (addedAuditTypeItem.name !== clickedItem.audit_type_item.name) {
            replaceTypeItemFilter(clickedItem.audit_type_item.name, addedAuditTypeItem.name, auditsFilterMap[type]);
        }

        await deleteMedia(deletedMedia);
        SetDeletedMedia([]);
        await submitImages(clickedItem.id);
        await submitVideos(clickedItem.id);
        const body = {
            ...clickedItem,
            notes: values.notes,
            location: audit.location.id,
            audit_type_item_id: clickedItem.audit_type_item.id,
            description: values.description,
        };
        if (type == "issue") {
            body.severity = values.issue_severity ?? clickedItem.severity;

            const newSeverity = issuesSeverities.find((item) => item.id === values.issue_severity);
            if (newSeverity?.name !== clickedItem.severity_object?.name) {
                replaceTypeItemFilter(clickedItem.severity_object?.name, newSeverity?.name, "Severity");
            }
        }
        body.audit_type_item_id = values.audit_type_item_id ?? clickedItem.audit_type_item.id;
        const endpoint = {area: "auditArea", issue: "auditIssue", line: "auditLine"};
        await makeUpdateRequest(`${API_AUDITS[endpoint[type]]}${clickedItem.id}/`, body)
            .then(async (jsonRes) => {
                insertNotification("Success", `Audit ${type} has been updated`, "success");
                updateItem(clickedItem.id, jsonRes, type);
            }).catch((error) => {
                insertNotification("Error", `Can't update audit ${type}. Reason: ${error.message}`, "error");
            });
    };
    const submitImages = async (auditElementId) => {
        const formData = new FormData();

        const images = item[`${type}_images`] || [];
        const highestSeq = images.reduce((max, img) => Math.max(max, img.image_sequence || 0), 0);

        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            if (image.mediaValue) {
                const compressedImage = await compressImage(image.mediaValue);

                const sequenceNumber = image.image_sequence ?? (highestSeq + i + 1);

                formData.append("image", compressedImage);
                formData.append("image_sequence", sequenceNumber);
                formData.append("image_value", compressedImage);
                formData.append("element_type", "compressedImage");
            }
        }

        // Submit the FormData
        await makeFormDataUpdateRequest(API_AUDITS.auditElementImage(type + "s", auditElementId), formData)
            .then(async (jsonRes) => { })
            .catch((error) => {
                insertNotification("Error", `Can't update Images ${type}. Reason: ${error.message}`, "error");
            });
    };

    const submitVideos = async (auditElementId) => {
        if (item[`${type}_videos`].length < 1) {
            return;
        }
        const formData = new FormData();
        await ffmpeg.load();
        for (let i = 0; i < item[`${type}_videos`].length; i++) {
            const video = item[`${type}_videos`][i];
            let videoValue = video.video_value;
            if (!videoValue || typeof videoValue === "string" || !(videoValue instanceof File)) {
                return;
            }
            if (videoCompressionFeature) {
                setLoadingProgress(0);
                setLoadingVideo(true);
                videoValue = await compressVideo(videoValue, ffmpeg, setLoadingProgress);
                setIsVideoCompressed(true);
            }
            formData.append("video_sequence", i);
            formData.append("video_value", videoValue);
            formData.append("element_type", "video");
        }

        await makeFormDataUpdateRequest(API_AUDITS.auditElementVideo(type + "s", auditElementId), formData)
            .then((jsonRes) => jsonRes)
            .catch((error) => {
                error.then((message) => {
                    insertNotification("Error", message.detail, "error");
                });
            });
        setLoadingVideo(false);
        setIsVideoCompressed(false);
    };

    return (
        <Modal
            onClose={onClose}
            open={isOpen}
            container={document.querySelector("#root .App")}
            aria-labelledby="annotation-modal"
        >
            <GSPSModalStyle>
                <GSPSPopUpHeader>
                    <div className="container py-3">
                        <h5 className="subtitle-md mb-0">Edit {type}</h5>
                    </div>
                </GSPSPopUpHeader>

                <div className="body">
                    <form className="w-100" id="issueForm" onSubmit={handleSubmit(onSubmit)}>
                        <div className="container">
                            <div className="row">
                                {(type === "line" || type === "area") && (
                                    <div className="col-lg-6 col-12">
                                        <>
                                            {type === "line" && (
                                                <DimensionsStyledContainer>
                                                    <GSPSLabelledInput
                                                        controlType={"input"}
                                                        id={"line_dimension"}
                                                        labelName={"Dimensions"}
                                                        errors={errors}
                                                        register={register}
                                                        isDisabled={true}
                                                        value={clickedItem.length_feet}
                                                    />
                                                </DimensionsStyledContainer>
                                            )}
                                            {type === "area" && (
                                                <DimensionsStyledContainer>
                                                    <GSPSLabelledInput
                                                        controlType={"input"}
                                                        id={"area_dimension"}
                                                        labelName={"Dimensions"}
                                                        errors={errors}
                                                        register={register}
                                                        isDisabled={true}
                                                        value={clickedItem.area_feet}
                                                    />
                                                </DimensionsStyledContainer>
                                            )}
                                        </>
                                    </div>
                                )}
                                {type === "issue" && issuesSeverities && (
                                    <div className="col-lg-6 col-12">
                                        <>
                                            <GSPSLabelledSelectFieldWithIcon
                                                labelName={"Severity"}
                                                inputID={"issue_severity"}
                                                selectableOptions={issuesSeverities}
                                                isRequired={true}
                                                isDisabled={isViewOnly}
                                                defaultValue={clickedItem?.severity}
                                                register={register}
                                            />
                                        </>
                                    </div>
                                )}
                                <div className="col-lg-6 col-12">
                                    <>
                                        {(availableTypeItems.length >= 1) &&
                                            <GSPSLabelledSelectFieldWithIcon
                                                inputID={"audit_type_item_id"}
                                                labelName={"Audit Type Item"}
                                                errors={errors}
                                                selectableOptions={availableTypeItems}
                                                register={register}
                                                isDisabled={isViewOnly}
                                                defaultValue={clickedItem.audit_type_item?.id}
                                            />
                                        }
                                    </>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <>
                                        <GSPSTextArea
                                            value={clickedItem?.description}
                                            labelName={"Custom Description"}
                                            id={"description"}
                                            controlType={"input"}
                                            isRequired={false}
                                            register={register}
                                            rows={7}
                                            errors={errors}
                                            isDisabled={isViewOnly}
                                            setValue={setValue}
                                            charLimit={250}
                                        />
                                        <GSPSTextArea
                                            value={clickedItem?.notes}
                                            labelName={"Notes"}
                                            id={"notes"}
                                            controlType={"input"}
                                            isRequired={false}
                                            register={register}
                                            rows={7}
                                            errors={errors}
                                            isDisabled={isViewOnly}
                                            setValue={setValue}
                                            charLimit={250}
                                        />
                                    </>
                                </div>
                                {item &&
                                    type &&
                                    item[`${type}_images`] &&
                                    clickedItem?.id === item?.id && (
                                        <div className="col-lg-6 col-12">
                                            <>
                                                <span className="fw-bold pb-0">
                                                    {!isViewOnly ? "Add" : ""} Image
                                                </span>
                                                <AuditImages
                                                    rowId={item?.displayNumber}
                                                    rowImages={[...item[`${type}_images`]]}
                                                    updateElementImages={updateElementImages}
                                                    elementType={type}
                                                    isViewOnly={isViewOnly}
                                                    limit={2}
                                                    deleteMedia={handleDelete}
                                                />
                                                {loadingVideo && videoCompressionFeature ?

                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: 2,
                                                        }}
                                                    ><CircularProgress
                                                            variant="determinate"
                                                            value={loadingProgress}
                                                            sx={{color: "#8CFAD0"}}
                                                        />
                                                        <p style={{margin: 0}}>{isVideoCompressed ? "Saving" : "We are compressing your video"}....</p>
                                                    </Box> :
                                                    <>
                                                        <span className="fw-bold pb-0">
                                                            {!isViewOnly ? "Add" : ""} Video
                                                        </span>
                                                        <AuditVideos
                                                            rowId={item.displayNumber}
                                                            rowVideos={item[`${type}_videos`]}
                                                            updateElementVideos={updateElementVideos}
                                                            elementType={type}
                                                            isViewOnly={isViewOnly}
                                                            limit={1}
                                                            deleteMedia={handleDelete}
                                                        />
                                                    </>
                                                }
                                            </>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </form>
                </div>

                <GSPSPopUpFooter>
                    <div className="container">
                        <div className="row d-flex justify-content-end align-items-center mx-0 w-100">
                            <div className="col-6 col-lg-2">
                                <GSPSCancelButton
                                    onClick={HandleClose}
                                    isDisabled={isSubmitting}
                                    controlSize={2}
                                    offset={0}
                                    buttonText={"Cancel"}
                                    form={"issueForm"}
                                    colClassName="w-100"
                                />
                            </div>
                            {!isViewOnly && (
                                <div className="col-6 col-lg-2 pe-0">
                                    <GSPSSubmitButton
                                        isDisabled={
                                            (!isDirty && touched) ||
                                            errors.zip?.message.length > 0 ||
                                            isSubmitting
                                        }
                                        isLoading={isSubmitting}
                                        controlSize={2}
                                        offset={0}
                                        buttonText={"Save"}
                                        onClick={handleSubmit}
                                        form={"issueForm"}
                                        colClassName="w-100"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </GSPSPopUpFooter>
            </GSPSModalStyle>
        </Modal>
    );
};

GSPSAnnotationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    clickedItem: PropTypes.object,
    isViewOnly: PropTypes.bool,
    audit: PropTypes.object,
    issuesSeverities: PropTypes.array,
    typeItems: PropTypes.array,
    deleteItem: PropTypes.func,
    deleteMedia: PropTypes.func,
    updateItem: PropTypes.func,
    type: PropTypes.oneOf(["area", "issue", "line"]).isRequired,
};

export default GSPSAnnotationModal;
