import {Toolbar, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs"
import GSPSPropDrivenTable from "Components/DataTable/GSPSPropDrivenTable";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {API_HISTORY} from "Services/Endpoints";
import {humanizeText, makeGetRequest} from "Services/ServicesUtils";
import {useTheme} from "styled-components";

const AuditLogs = () => {
    const {auditID} = useParams();
    const [auditHistory, setAuditHistory] = useState([])
    const [auditLoading, setAuditLoading] = useState([]);

    const theme = useTheme();

    useEffect(() => {
        fetchAuditsHistory();
    }, [])

    const fetchAuditsHistory = () => {
        setAuditLoading(true)
        makeGetRequest(`${API_HISTORY.audits}?audit_id=${auditID}`).then((res) => {
            setAuditHistory(res.audit_history);
        }).catch((e) => console.error(e))
            .finally(() => setAuditLoading(false));
    }

    const auditHistoryColumns = [
        {
            field: 'history_date',
            headerName: 'Date',
            flex: 1,
            type: "dateTime",
            valueGetter: (params) => {
                return new Date(params);
            }
        },
        {
            field: 'history_user',
            headerName: 'User',
            flex: 1,
            renderCell: (params) => {
                return params?.row?.history_user?.full_name || "-"
            },
        },
        {
            field: 'history_type',
            headerName: 'Action',
            flex: 1,
            valueGetter: (params) => {
                return params === "+" ? "Created" :
                    params === "-" ? "Deleted" :
                        params === "~" ? "Modified" : "-"
            }
        },
        {
            field: 'changed_fields',
            headerName: 'Changed Fields',
            flex: 1,
            renderCell: (params) => {
                const value = params.row.changed_fields;
                const fieldsArray = value ? value.split(',').map(field => field.trim()) : [];

                return (
                    <>
                        {
                            value ? <ul className="ps-3 m-0">
                                {fieldsArray.map((field, index) => (
                                    <li key={index}>{humanizeText(field)}</li>
                                ))}
                            </ul> : "-"
                        }
                    </>
                );
            }
        },
        {
            field: 'list_changes',
            headerName: 'Changes',
            flex: 2,
            renderCell: (params) => {
                const value = params.row.list_changes;
                const changesArray = value ? value.split(';').map(change => change.trim()) : [];

                return (
                    <>
                        {
                            value ? <ul className="ps-3 m-0">
                                {changesArray.map((change, index) => (
                                    <li key={index}>{humanizeText(change)}</li>
                                ))}
                            </ul> : "-"
                        }
                    </>
                );
            }
        },
    ];

    return <div className="AuditLogs">
        <GSPSBreadCrumbs staticCrumb={`Audit Activity Logs / ${auditID}`} />

        <div className="container">
            <GSPSPropDrivenTable
                title="Audit History"
                data={auditHistory}
                columns={auditHistoryColumns}
                uniqueTableID={(row) => row?.history_id}
                disableColumnMenu
                loading={auditLoading}
                rowHeight="auto"
            />
        </div>
    </div>
}

export default AuditLogs;