import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { GSPSSubmitButtonStyle } from "Components/Common/GSPSSubmitButton.styles";
import { insertNotification } from "Utils/NotificationsUtils";
import AuditsIntroSection from "./AuditsIntroSection";
import GSPSMenu from "Components/DataTable/GSPSMenu";
import CircleIcon from "@mui/icons-material/Circle";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SendIcon from "@mui/icons-material/Send";
import HistoryIcon from "@mui/icons-material/History";
import { AuditsPage } from "Styles/Audits.styles";
import { Box, Tab, Tabs } from "@mui/material";
import { useArchiveAuditMutation, useCloneAuditMutation, useDeleteAuditMutation, useFetchAuditsQuery, useUnarchiveAuditMutation } from "api/AuditTableAPI";
import { GSPSDataDrivenTable } from "Components/DataTable/GSPSDataDrivenTable";
import { UserContext } from "context/UserContext";
import { useWindowSize } from "hooks/useWindowSize";

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
};

const a11yProps = (index) => {
    return {
        "id": `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

export const Audits = ({ locationID = null }) => {
    const navigate = useNavigate();
    const [tableKey, setTableKey] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const { permissions, user } = useContext(UserContext);
    const isUserAdmin = permissions.isAdmin || permissions.isGroupAdmin;
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 768;
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const auditColumns = [
        {
            name: "Name",
            accessor: "name",
            type: "text",
            render: (audit) => {
                const linkPath =
                    (audit.row.audit_status?.name === "In Progress" ||
                        audit.row.audit_status?.name === "Edits Required" ||
                        audit.row.audit_status?.name === "In Review") ?
                        `/audits/creator?auditId=${audit.id}` :
                        `/audits/${audit.id}/?report=false`;

                return (
                    <NavLink to={linkPath} value={audit.id}>
                        {audit.formattedValue}
                    </NavLink>);
            },
        },
        {
            name: "Account",
            accessor: "account_name",
            type: "text",
        },
        {
            name: "Location",
            accessor: "full_address",
            type: "text",
        },
        {
            name: "Status",
            accessor: "audit_status.name",
            type: "text",
            render: (params) => {
                const status = params.formattedValue;
                let statusClass = "auditsCreated";
                switch (status) {
                    case "Completed":
                        statusClass = "auditsCompleted";
                        break;
                    case "In Review":
                        statusClass = "auditsInReview";
                        break;
                    case "In Progress":
                        statusClass = "auditsCreated";
                        break;
                    default:
                        statusClass = "auditsRequestEdits";
                        break;
                }

                return (
                    <div className={`d-flex align-items-center ${statusClass}`}>
                        <CircleIcon fontSize="small" className="me-2" />
                        <span>
                            {status}
                        </span>
                    </div>
                );
            },
            value: (value, row) => {
                return row.audit_status.name
            },
            filter: "select",
            filterOptions: [
                { label: "Any", value: "" },
                { label: "Completed", value: "completed" },
                { label: "In Review", value: "in review" },
                { label: "In Progress", value: "in progress" },
                { label: "Request Edits", value: "edits required" }
            ]
        },
        ...(!isMobile ? [
            {
                name: "User",
                accessor: "created_by_full_name",
                type: "text",
            },
            {
                name: "Created on",
                accessor: "created_at",
                type: "date",
            },
            {
                name: "Updated on",
                accessor: "updated_at",
                type: "date",
            },
        ] : []),
        {
            name: "Actions",
            type: "action",
            render: (audit) => {
                const actionsList = tabValue === 1 ? [
                    {
                        text: "Unarchive",
                        icon: <UnarchiveIcon fontSize={"medium"} />,
                        handleClick: () => handleUnarchive(audit),
                    },
                    {
                        text: "Delete",
                        icon: <DeleteForeverIcon fontSize={"medium"} />,
                        handleClick: () => handleDelete(audit),
                    },
                ] : [
                    {
                        text: "Send Report",
                        icon: <SendIcon fontSize={"medium"} />,
                        handleClick: () => navigate(`/audits/${audit.id}/`),
                        disabled: !isUserAdmin || !(audit.row.audit_status.name === "Completed"),
                    },
                    {
                        text: "Clone",
                        icon: <CopyAllIcon fontSize={"medium"} />,
                        handleClick: () => handleClone(audit),
                    },
                    {
                        text: "Archive",
                        icon: <ArchiveIcon fontSize={"medium"} />,
                        handleClick: () => handleArchive(audit),
                    },
                    {
                        text: "Activity Logs",
                        icon: <HistoryIcon fontSize={"medium"} />,
                        handleClick: () => navigate(`/audits/logs/${audit.id}`),
                        disabled: !isUserAdmin,
                    },
                ];

                return <GSPSMenu actions={actionsList} />;
            }
        },
    ];

    const [dashboardColumns, setDashboardColumns] = useState(auditColumns);
    const [archiveAudit] = useArchiveAuditMutation();
    const [unarchiveAudit] = useUnarchiveAuditMutation();
    const [deleteAudit] = useDeleteAuditMutation();
    const [cloneAudit] = useCloneAuditMutation();

    useEffect(() => {
        setDashboardColumns(auditColumns);
    }, [auditColumns]);

    useEffect(() => {
        if (user?.default_dashboard_columns) {
            setDashboardColumns((prev) =>
                prev.filter((col) =>
                    user.default_dashboard_columns.some((defaultCol) =>
                        col.name?.trim().toLowerCase() === defaultCol.trim().toLowerCase()
                    )
                )
            );
        }
    }, [user, dashboardColumns]);

    const handleClone = async (audit) => {
        try {
            await cloneAudit(audit.id).unwrap();
            insertNotification("Success", `Audit ${audit.id} has been cloned.`, "success");
        } catch (err) {
            insertNotification("Error", `Can't clone audit: ${err.message}`, "error");
        }
    };

    const handleArchive = async (audit) => {
        try {
            await archiveAudit(audit.id).unwrap();
            insertNotification("Success", `Audit ${audit.id} has been archived.`, "success");
        } catch (err) {
            insertNotification("Error", `Can't archive audit: ${err.message}`, "error");
        }
    };

    const handleUnarchive = async (audit) => {
        try {
            await unarchiveAudit(audit.id).unwrap();
            insertNotification("Success", `Audit ${audit.id} is now active.`, "success");
        } catch (err) {
            insertNotification("Error", `Can't unarchive audit: ${err.message}`, "error");
        }
    };

    const handleDelete = async (audit) => {
        try {
            await deleteAudit(audit.id).unwrap();
            insertNotification("Success", `Audit ${audit.id} is deleted successfully`, "success");
        } catch (err) {
            insertNotification("Error", `Can't delete Audit ${audit.id}. Reason: ${err.message}`, "error");
        }
    };

    return (
        <AuditsPage>
            <AuditsIntroSection updateStats={tableKey} />
            <div className="container position-relative z-2">
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs">
                    <Tab label="Active" {...a11yProps(0)} className="subtitle-sm" />
                    <Tab label="Archived" {...a11yProps(1)} className="subtitle-sm" />
                </Tabs>

                <CustomTabPanel value={tabValue} index={0}>
                    <GSPSDataDrivenTable
                        title="Audit Inventory"
                        columns={dashboardColumns}
                        disableColumnMenu
                        tableAdminBtns={
                            [
                                {
                                    text: "Create new audit",
                                    handleClick: "/audits/creator",
                                },
                                {
                                    text: "Audit this location",
                                    handleClick: "/audits/creator?autoLocation=true",
                                    primary: true
                                }
                            ]
                        }
                        useQuery={useFetchAuditsQuery}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    <GSPSDataDrivenTable
                        title="Audit Inventory (archived)"
                        columns={dashboardColumns}
                        disableColumnMenu
                        useQuery={useFetchAuditsQuery}
                        active={false}
                        tableAdminBtns={
                            [
                                {
                                    text: "Create new audit",
                                    handleClick: "/audits/creator",
                                },
                                {
                                    text: "Audit this location",
                                    handleClick: "/audits/creator?autoLocation=true",
                                    primary: true
                                },
                            ]
                        }
                    />
                </CustomTabPanel>

            </div>
        </AuditsPage>
    );
};

Audits.propTypes = {
    locationID: PropTypes.string,
};
