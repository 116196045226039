import styled from "styled-components";
import {Row, Modal} from "react-bootstrap";

export const StyledLogoUploadContainer = styled.div`
    display: flex;
    gap: 20px;
    width: max-content;
`

export const GSPSPageWrapper = styled(Modal)(({theme}) => ({
    '& .modal': {
        width: "100%"
    },
    '& .modal-lg': {
        width: '770px'
    },
    '@media (max-width: 576px)': {
        '& .modal-lg': {
            width: 'unset'
        },
    },
}));

export const GSPSModalStyle = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    background-color: ${({theme}) => theme.color.bodyBackground};
    border: 2px solid ${({theme}) => theme.color.border};
    border-radius: 12px;
    overflow: hidden;
    max-height: 95dvh; 
    overflow-y: auto; 
    
    @media (max-width: 820px) {
        width: 90%;
    }
`


export const GSPSInputContainer = styled.div`
    padding-left: 15px;
    padding-right: 15px;
`;

export const GSPSEnableDisabledContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 15px 0px 15px 0px;
`;

export const GSPSPopUpHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    isolation: isolate;
    background-color: ${({theme}) => theme.color.headerFooterlight};
    border-bottom: 1px solid ${({theme}) => theme.color.headerFooterBorderlight};
`

export const GSPSPopUpFooter = styled.div`
    position: sticky;
    bottom: 0;
    z-index: 100;
    background: ${({theme}) => theme.color.headerFooterlight};
    border-top: 1px solid ${({theme}) => theme.color.headerFooterBorderlight};
    & .container{
        margin-top: 10px;
        padding-bottom: 10px;
        gap: 12px;
    }
`

export const GSPSStyledRow = styled(Row)`
    gap: 60px;
}
`

